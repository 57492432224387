<template>
    <el-popover
        ref="itapPopover"
        placement="bottom-start"
        popper-class="itap-popover"
        width="560"
        trigger="hover"
        v-if="data.tags&&data.tags.length > 0"
        :visible-arrow="false"
        :open-delay="700"
        :offset="500"
        @after-enter="itapPopoverShow"
        @hide="itapPopoverHide"
    >
        <span slot="reference" class="talent-label">{{data.tags[0].tagName}}</span>
        <div v-loading="loading">
            <div class="table-popper-header">
                标签
                <el-tooltip
                    placement="right-start"
                    effect="light"
                    popper-class="itap-tooltip"
                    :visible-arrow="false"
                    :open-delay="700"
                    :offset="20"
                    v-if="isResume"
                >
                    <div slot="content">
                        您了解“标签（Tag）”吗？
                        <br/>
                        标签是任由自己创造的，它是我们自己的一种分类方式。标签就是候选人的关键字、属性，帮助你快速分类查找候选人。比起传统的文件夹方式，标签管理的最大优点就是可以设定多个不同的标签第二行信息
                    </div>
                    <span class="icon-question">
                        <font-icon class="talent-icon-middle question-hover" href="#icon-talent_ql"></font-icon>
                        <font-icon class="talent-icon-middle question-no-hover" href="#icon-talent_qd"></font-icon>
                    </span>
                </el-tooltip>
                <span class="text-operate-btn blue label-management-btn" @click="showLabelManagement" v-if="isResume">标签管理</span>
            </div>
            <add-itap
                ref="addItap"
                :tagData="tagData"
                :isResume="isResume"
                @show-add-itap-area="showAddItapArea"
            ></add-itap>
        </div>
    </el-popover>
</template>

<script>
import AddItap from '@src/component/common/addItap.vue';
import popover from '@src/js/mixins/popover';
import emitter from '@src/js/mixins/emitter';
export default {
    name: 'add-itap-popover',
    data() {
        return {
            spanTop: 0,
            top: 0,
            loading: true,
        }
    },
    mixins: [popover, emitter],
    props: {
        tagData: {
            type: Object
        },
        isResume: {
            type: Boolean,
            default: true
        }
    },

    computed: {
        data() {
            return this.isResume ? this.tagData.resumeListItem : this.tagData;
        }
    },

    components:{
        AddItap,
    },
    
    methods: {
        itapPopoverShow() {
            this.popoverShow(this.$refs.itapPopover, 138);
            this.loading = false;
        },
        itapPopoverHide() {
            this.$refs.addItap.isShowAddItap = false;
        },
        showAddItapArea() {
            if(this.spanTop > 660) {
                let addItapAreaHeight = this.$refs.addItap.$refs.addItapArea.clientHeight;
                this.$refs.itapPopover.popperElm.style.top = (this.top - addItapAreaHeight + 10) + 'px';
            }
        },
        showLabelManagement() {
            this.dispatch('TableWrapper', 'showLabelManagement');
        }
    }
}
</script>

<style lang="scss" scoped>
.talent-label {
    display: inline-block;
    margin-top: 8px;
    height: 20px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 20px;
    padding: 0 6px;
    color: $primary;
    background-color: rgba(56,188,157,0.1);
    border-radius: 4px;
}

.table-popper-header span {
    font-size: 14px;
    color: #4A90E2;
    margin-left: 6px;
    cursor: pointer;
}
</style>

<style lang="scss">
.label-management-btn {
    font-weight: normal;
    margin-left: 10px;
}
</style>