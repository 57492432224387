var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "add-itap",
      class: _vm.isBatch ? "is-in-dialog" : "",
    },
    [
      _vm.newChoosedItaps.length > 0
        ? _c(
            "div",
            { staticClass: "choosed-itap-list" },
            _vm._l(_vm.newChoosedItaps, function (itap, index) {
              return _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: itap,
                      expression: "itap",
                    },
                  ],
                  key: index,
                  staticClass: "itap-item choosed-itap-item",
                },
                [
                  _vm._v("\n            " + _vm._s(itap) + "\n            "),
                  _c("i", {
                    staticClass: "el-icon-circle-close itap-delete",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.deleteItap(itap, index)
                      },
                    },
                  }),
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _c(
        "el-button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                !_vm.isBatch &&
                !_vm.isShowAddItap &&
                _vm.newChoosedItaps.length > 0,
              expression:
                "!isBatch && !isShowAddItap && newChoosedItaps.length > 0",
            },
          ],
          staticClass: "add-itap-button",
          attrs: { icon: "el-icon-circle-plus-outline add-itap-icon" },
          on: { click: _vm.showAddItap },
        },
        [_vm._v("\n        添加\n    ")]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.isBatch ||
                _vm.isShowAddItap ||
                _vm.newChoosedItaps.length == 0,
              expression:
                "isBatch || isShowAddItap || newChoosedItaps.length == 0",
            },
          ],
          ref: "addItapArea",
        },
        [
          _c(
            "div",
            { staticClass: "itap-defined" },
            [
              _c("el-input", {
                attrs: { placeholder: "添加新标签", maxlength: 20 },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.definedItap.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.definedItapText,
                  callback: function ($$v) {
                    _vm.definedItapText = $$v
                  },
                  expression: "definedItapText",
                },
              }),
              _c(
                "el-button",
                {
                  staticClass: "itap-defined-btn",
                  attrs: { type: "primary" },
                  on: { click: _vm.definedItap },
                },
                [_vm._v("添加")]
              ),
            ],
            1
          ),
          _vm.newMyItaps.length > 0
            ? _c(
                "div",
                { staticClass: "my-tag-list" },
                [
                  _vm.isResume
                    ? [
                        _vm._l(
                          _vm.newMyItaps,
                          function (category, categoryIndex) {
                            return [
                              category.tagDataList &&
                              category.tagDataList.length > 0
                                ? _c(
                                    "div",
                                    {
                                      key: categoryIndex,
                                      staticClass: "category-tag-item",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "category-title" },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(category.categoryName) +
                                              "\n                        "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "tag-list" },
                                        [
                                          _vm._l(
                                            category.tagDataList,
                                            function (tag, tagIndex) {
                                              return _c(
                                                "span",
                                                {
                                                  key: tagIndex,
                                                  staticClass: "tag-item",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.chooseAddItap(
                                                        tag.tagName
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                    " +
                                                      _vm._s(tag.tagName) +
                                                      "\n                                "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          }
                        ),
                      ]
                    : _vm._l(_vm.newMyItaps, function (tag, tagIndex) {
                        return _c(
                          "span",
                          {
                            key: tagIndex,
                            staticClass: "tag-item",
                            on: {
                              click: function ($event) {
                                return _vm.chooseAddItap(tag)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(tag) +
                                "\n                "
                            ),
                          ]
                        )
                      }),
                ],
                2
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }