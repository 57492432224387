<template>
    <el-dialog
        class="add-itap-dialog"
        :class="{'single-add-itap': !isBatch, 'is-guide': labelManagementBtnGuide}"
        :visible.sync="isShow"
        v-bind:show-close="false"
        @close="hideDialog"
        :close-on-click-modal="false">
        <span v-if="isBatch" slot="title">
            批量打标签
            <div class="label-management-btn-guide">
                <span
                    class="text-operate-btn blue label-management-btn"
                    :class="{'is-btn-guide': labelManagementBtnGuide}"
                    @click="showLabelManagement"
                    v-if="isResume">
                    标签管理
                </span>
                <div class="step-guide" v-if="labelManagementBtnGuide">
                    <span class="step-guide-box">
                        <span class="guide-content">
                            点击标签管理，即可对标签进行分类、编辑和删除。
                        </span>
                        <span class="guide-btn">
                            <span class="text-operate-btn" @click="hideLabelManagementGuide">不再提醒</span>
                            <span class="text-operate-btn" @click="labelManagementBtnGuide = false">好的</span>
                        </span>
                    </span>
                    <span class="step-guide-arrows"></span>
                </div>
            </div>
        </span>
        <div v-loading="loading">
            <div class="table-popper-header" v-if="!isBatch">
                标签
                <el-tooltip
                    placement="right-start"
                    effect="light"
                    popper-class="itap-tooltip"
                    :visible-arrow="false"
                    :offset="20" v-if="isResume"
                >
                    <div slot="content">
                        您了解“标签（Tag）”吗？
                        <br/>
                        标签是任由自己创造的，它是我们自己的一种分类方式。标签就是候选人的关键字、属性，帮助你快速分类查找候选人。比起传统的文件夹方式，标签管理的最大优点就是可以设定多个不同的标签第二行信息
                    </div>
                    <span class="icon-question">
                        <font-icon class="talent-icon-middle question-hover" href="#icon-talent_ql"></font-icon>
                        <font-icon class="talent-icon-middle question-no-hover" href="#icon-talent_qd"></font-icon>
                    </span>
                </el-tooltip>
                <div class="label-management-btn-guide">
                    <span
                        class="text-operate-btn blue label-management-btn"
                        :class="{'is-btn-guide': labelManagementBtnGuide}"
                        @click="showLabelManagement"
                        v-if="isResume">
                        标签管理
                    </span>
                    <div class="step-guide" v-if="labelManagementBtnGuide">
                        <span class="step-guide-box">
                            <span class="guide-content">
                                点击标签管理，即可对标签进行分类、编辑和删除。
                            </span>
                            <span class="guide-btn">
                                <span class="text-operate-btn" @click="hideLabelManagementGuide">不再提醒</span>
                                <span class="text-operate-btn" @click="labelManagementBtnGuide = false">好的</span>
                            </span>
                        </span>
                        <span class="step-guide-arrows"></span>
                    </div>
                </div>
                <i class="el-icon-close" @click="hideDialog"></i>
            </div>
            <add-itap
                ref="addItap"
                :isBatch="isBatch"
                :isResume="isResume"
                :tagData="!isBatch && list.length > 0 ? list[0] : {}"
                :tagDataIds="isBatch ? ids : []"
            ></add-itap>
        </div>
        <span slot="footer" class="dialog-footer" v-if="isBatch">
            <el-button @click="hideDialog">取消</el-button>
            <el-button type="primary" @click="handleBatchAddItap">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import AddItap from '@src/component/common/addItap.vue';
import talentPoolService from '@src/js/service/talentPoolService.js';
import emitter from '@src/js/mixins/emitter';
import myOrdersService from "@src/js/service/myOrdersService.js";

export default {
    name: 'add-itap-dialog',
    components: {
        AddItap,
    },
    props: {
        isResume: {
            type: Boolean,
            default: true
        },
        type:{
            type:String,
            default:''
        }
    },
    mixins: [emitter],
    data() {
        return {
            isShow: false,
            isBatch: false,
            ids: [],
            indexJson: {},
            list: [],
            loading: false,
            labelManagementBtnGuide: false
        }
    },
    mounted() {
        if(!localStorage.getItem('labelManagementBtnGuide')) {
            this.labelManagementBtnGuide = true;
        }
    },
    methods: {
        show(isBatch) {
            this.isBatch = isBatch;
            if(isBatch) {
                let idArray = [];
                if(this.isResume) {
                    this.list.forEach((item) => {
                        idArray.push(item.resumeListItem.resumeId);
                    });
                } else {
                    this.list.forEach((item) => {
                        idArray.push(item.jobId);
                    });
                }
                this.ids = idArray;
            }
            this.isShow = true;
        },
        hideDialog() {
            this.isShow = false;
            this.$refs.addItap.newChoosedItaps = [];
            this.$refs.addItap.isShowAddItap = false;
            this.$refs.addItap.definedItapText = '';
        },
        batchAddItap() {
            if(this.$refs.addItap.newChoosedItaps.length > 0) {
                this.loading = true;
                talentPoolService.addItapToResume({
                    objectIds: this.ids,
                    tagsName: this.$refs.addItap.newChoosedItaps,
                    type: 2
                }).then((res) => {
                    this.loading = false;
                    if(res) {
                        // shortTips(`批量添加标签简历成功${res.successCount}份，失败${res.failCount}份`);
                        shortTips("添加成功");
                    }
                    this.isShow = false;
                    this.$emit('update-list');
                    this.dispatch('TableWrapper', 'refresh-search-tag');
                }).catch((err) => {
                    this.loading = false;
                    this.isShow = false;
                    console.log(err);
                });
            } else {
                this.isShow = false;
            }
        },
        handleBatchAddItap(){
            //默认执行talent-pool，其它传type做区分
            switch (this.type) {
                case 'myOrders':
                    this.batchAddItapForOrders();
                    break;
                default:
                    this.batchAddItap();
                    break;
            }
        },
        batchAddItapForOrders(){
            //myOrders的批量处理
            if(this.$refs.addItap.newChoosedItaps.length > 0) {
                myOrdersService.createTag({
                    objectIds:this.ids,
                    tagsName: this.$refs.addItap.newChoosedItaps,
                    type: 2
                })
                    .then(res => {
                        if(res){
                            shortTips('添加成功');
                            this.isShow = false;
                            this.$emit('update-list');
                        }

                    })
                    .catch(err => {
                        console.log(err);
                        this.loading = false;
                    });
            }else{
                this.isShow = false;
            }

        },
        showLabelManagement() {
            this.hideDialog();
            this.$emit('showLabelManagement');
        },
        hideLabelManagementGuide() {
            localStorage.setItem('labelManagementBtnGuide', true);
            this.labelManagementBtnGuide = false;
        }
    }
}
</script>

<style lang="scss" scope>
.add-itap-dialog {
    &.is-guide {
        .el-dialog::before {
            content: "";
            width: 100%;
            height: 100%;
            border-radius: 6px;
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 4000;
            background-color: rgba(0,0,0,0.5);
        }
    }
    .el-dialog {
        width: 608px;
    }
    .el-dialog__header {
        font-size: 18px;;
    }
    .el-dialog__body {
        color: #666;
    }
    .el-dialog__footer{
        padding-top: 0;
    }
    .label-management-btn-guide {
        display: inline-block;
        z-index: 4001;
        position: relative;
        .step-guide {
            position: absolute;
            z-index: 4001;
            left: 140px;
            top: 0;
            font-size: 14px;
            line-height: 24px;
            font-weight: normal;
            .step-guide-box {
                position: relative;
                display: inline-block;
                width: 340px;
                height: 72px;
                border: 2px dashed rgba(0, 0, 0, 0.4);
                background-color: #fff;
                border-radius: 12px;
                padding: 10px;
            }
            .guide-content {
                color: #666;
                line-height: 24px;
            }
            .guide-btn {
                position: absolute;
                bottom: 10px;
                right: 18px;
                .text-operate-btn + .text-operate-btn {
                    margin-left: 10px;
                }
            }
            .step-guide-arrows {
                position: absolute;
                top: 27px;
                left: -62px;
                width: 62px;
                height: 2px;
                border-top: 2px dashed #fff;
                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 2px;
                    height: 18px;
                    background-color: #fff;
                    transform: rotate(-60deg);
                    transform-origin: top left;
                }
                &::after {
                    content: "";
                    position: absolute;
                    bottom: 2px;
                    left: 0;
                    width: 2px;
                    height: 18px;
                    background-color: #fff;
                    transform: rotate(60deg);
                    transform-origin: bottom left;
                }
            }
        }
    }
    .label-management-btn {
        font-weight: normal;
        margin-left: 10px;
        &.is-btn-guide {
            pointer-events: none;
            background-color: #fff;
            display: inline-block;
            border-radius: 6px;
            height: 24px;
            line-height: 24px;
            padding: 0 6px;
            margin-left: 7px;
        }
    }
}
.single-add-itap {
    .el-dialog {
        width: 560px;
        min-height: 175px;
        // max-height: 340px;
    }
    .el-dialog__header {
        display: none;
    }
    .el-dialog__body {
        padding: 16px 0 20px 18px;
    }
    .label-management-btn-guide {
        .step-guide {
            top: -16px;
        }
    }
}
.itap-tooltip.el-tooltip__popper {
    border: none;
    width: 320px;
    background: #FFFFFF;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.30);
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 12px;
    color: #666666;
    line-height: 20px;
}
</style>
