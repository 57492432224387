var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.data.tags && _vm.data.tags.length > 0
    ? _c(
        "el-popover",
        {
          ref: "itapPopover",
          attrs: {
            placement: "bottom-start",
            "popper-class": "itap-popover",
            width: "560",
            trigger: "hover",
            "visible-arrow": false,
            "open-delay": 700,
            offset: 500,
          },
          on: { "after-enter": _vm.itapPopoverShow, hide: _vm.itapPopoverHide },
        },
        [
          _c(
            "span",
            {
              staticClass: "talent-label",
              attrs: { slot: "reference" },
              slot: "reference",
            },
            [_vm._v(_vm._s(_vm.data.tags[0].tagName))]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
            },
            [
              _c(
                "div",
                { staticClass: "table-popper-header" },
                [
                  _vm._v("\n            标签\n            "),
                  _vm.isResume
                    ? _c(
                        "el-tooltip",
                        {
                          attrs: {
                            placement: "right-start",
                            effect: "light",
                            "popper-class": "itap-tooltip",
                            "visible-arrow": false,
                            "open-delay": 700,
                            offset: 20,
                          },
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [
                              _vm._v(
                                "\n                    您了解“标签（Tag）”吗？\n                    "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                    标签是任由自己创造的，它是我们自己的一种分类方式。标签就是候选人的关键字、属性，帮助你快速分类查找候选人。比起传统的文件夹方式，标签管理的最大优点就是可以设定多个不同的标签第二行信息\n                "
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            { staticClass: "icon-question" },
                            [
                              _c("font-icon", {
                                staticClass:
                                  "talent-icon-middle question-hover",
                                attrs: { href: "#icon-talent_ql" },
                              }),
                              _c("font-icon", {
                                staticClass:
                                  "talent-icon-middle question-no-hover",
                                attrs: { href: "#icon-talent_qd" },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.isResume
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "text-operate-btn blue label-management-btn",
                          on: { click: _vm.showLabelManagement },
                        },
                        [_vm._v("标签管理")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("add-itap", {
                ref: "addItap",
                attrs: { tagData: _vm.tagData, isResume: _vm.isResume },
                on: { "show-add-itap-area": _vm.showAddItapArea },
              }),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }